<template>
  <Main channel="02067182521" ref="mnbk" id="mnbk" />
</template>
  
  <script>
import Main from './Main.vue'
export default {
  components: {
    Main,
  },
  mounted() {
    var _czc = window._czc || [];
    (function () {
      var um = document.createElement("script");
      um.src = "https://s9.cnzz.com/z.js?id=1281356678&async=1";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(um, s);
    })();
  },
}
  </script>
  